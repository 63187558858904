<template>
  <ClientOnly>
    <Map 
      :markers="markers"
      :zoom-control="true"
      :withCurrentLocation="!!isGeoSearch"
      :height="!!grid.sm ? 300 : 200" 
    />
    <template #fallback>
      <USkeleton class="rounded-lg h-[300px]" />
    </template>
  </ClientOnly>
</template>

<script lang="ts" setup>

type ContentKey = 'regioloketten' | 'onderwijsinstellingen' | 'opleidingen'

const props = defineProps<{
  data: Array<{
    key: ContentKey
    data: Array<AlgoliaRegioloket | AlgoliaOnderwijsInstelling | AlgoliaOpleiding>
  }>
}>()

const { isGeoSearch, isGeolocArray } = useGeolocation()

const { grid } = useResponsive()
const { sectors } = useConstants()
import type { AlgoliaOnderwijsInstelling, AlgoliaOpleiding, AlgoliaRegioloket } from '~~/types/algolia';
import type { Marker } from '~~/types/map';

const markers: Ref<Marker[]> = computed(() => {
  const markerList = []

  if (!!props.data.find(type => type.key === 'regioloketten')) {
    // @ts-expect-error
    const { data } = props.data.find(type => type.key === 'regioloketten')
    markerList.push(...data.map((rec: AlgoliaRegioloket) => {
      return {
        id: rec.id,
        lat: isGeolocArray(rec) ? rec._geoloc_center?.lat as number : rec._geoloc?.lat,
        lng: isGeolocArray(rec) ? rec._geoloc_center?.lng as number : rec._geoloc?.lng,
        type: 'regioloket' as Marker["type"],
        popover: {
          label: rec.name as string,
          type: 'Regioloket',
          tags: rec.sectoren.map((s: string) => sectors.find(sec => sec.label === s || sec.altNames.includes(s))?.id).filter((val: string | undefined) => !!val) as string[],
          url: rec.url || rec.profileUrl as string
        }
      }
    }).filter((rec: any) => !!rec.lat && !!rec.lng))
  }
  if (!!props.data.find(type => type.key === 'onderwijsinstellingen')) {
    // @ts-expect-error
    const { data } = props.data.find(type => type.key === 'onderwijsinstellingen')
    markerList.push(...data.map((rec: AlgoliaOnderwijsInstelling) => {
      return {
        id: rec.id,
        lat: isGeolocArray(rec) ? rec._geoloc_center?.lat as number : rec._geoloc?.lat as number,
        lng: isGeolocArray(rec) ? rec._geoloc_center?.lng as number : rec._geoloc?.lng  as number,
        type: 'onderwijsinstelling' as Marker["type"],
        popover: {
          label: rec.name as string,
          type: 'Onderwijsinstelling',
          tags: rec.soort as string[],
          url: rec.url || rec.profileUrl as string
        }
      }
    }).filter((rec: any) => !!rec.lat && !!rec.lng))
  }

  if (!!props.data.find(type => type.key === 'opleidingen')) {
    // @ts-expect-error
    const { data } = props.data.find(type => type.key === 'opleidingen')
    markerList.push(...data.map((rec: AlgoliaOpleiding) => {
      return {
        id: rec.global_index_id || rec.id,
        lat: isGeolocArray(rec) ? rec._geoloc_center?.lat as number : rec._geoloc?.lat as number,
        lng: isGeolocArray(rec) ? rec._geoloc_center?.lng as number : rec._geoloc?.lng  as number,
        type: rec.contentType as Marker["type"] || 'opleiding',
        popover: {
          label: rec.name as string,
          type: capitalizeFirstChar(rec.contentType || '') as string,
          url: rec.url || rec.profileUrl as string || null
        }
      }
    }).filter((rec: any) => !!rec.lat && !!rec.lng))
  }
  return markerList
})

</script>

<style>

</style>